<template>
  <div :class="`${!Boolean(link) ? 'px-4' : ''}`">
    <cc-section-header
      v-if="!Boolean(link)"
      :title="$t('publicGoalProgressTitle')"
      align="start"
      heading-align="left"
      />
    <cc-card
      :id="id"
      :ruid="id"
      :inner-title="null"
      :has-button="false"
      :variant="null"
      card-background="blue-dark"
      :card-class="`${
        Boolean(link) ? (isLoading ? 'p-7' : 'p-5') : isLoading ? 'p-6' : 'p-4'
      } position-relative`"
      :pressable="Boolean(link)"
      @click.native="link ? $router.push({ name: link }).catch(() => {}) : null"
      >
      <template #body>
        <div
          class="position-absolute"
          style="right: 0; bottom: 0; z-index: 0"
          >
          <cc-svg-base svg="illustrations/public-challenge-progress" />
        </div>
        <cc-charts-loading :is-loading="isLoading">
          <div class="position-relative">
            <div class="d-flex flex-row pb-4 justify-content-between">
              <cc-svg-base
                svg="illustrations/public-goal"
                width="135px"
                height="40px"
                />
              <div class="my-auto">
                <cc-button
                  v-if="link"
                  variant="small"
                  :block="false"
                  :text="$t('txtDetails')"
                  />
              </div>
            </div>
            <cc-text
              variant="body-sm"
              color="muted"
              class="preline-formatted"
              >
              {{ $t('publicGoalCardProgressTitle', { numberOfTrips }) }}
            </cc-text>
            <cc-heading variant="h2">
              {{ savedCo2 }}
            </cc-heading>
            <cc-text
              variant="body"
              color="muted"
              class="text-pre-line"
              >
              {{ $t('publicGoalCardProgressUnit') }}
            </cc-text>
          </div>
        </cc-charts-loading>
      </template>
    </cc-card>
  </div>
</template>
<script>
import ccSvgBase from '@/components/SvgBase.vue';
import ccCard from '@/components/constructs/Card.vue';
import ccHeading from '@/components/primitives/Heading.vue';
import ccSectionHeader from '@/components/constructs/SectionHeader.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';

import { mapState } from 'vuex';

export default {
  components: {
    ccSvgBase,
    ccCard,
    ccHeading,
    ccSectionHeader,
    ccChartsLoading,
    // ccLoading,
  },
  props: {
    link: {
      type: String,
      required: false,
      default: null,
    },
    isProgressPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'public-goal-progress-card',
      avgAccumulators: null,
      goalEmission: null,
      challengeActiveUsers: 0,
      totalTrips: 0,
      isLoading: true,
      goalCo2: 1000000,
      totalDistance: 0,
    };
  },
  computed: {
    ...mapState({
      getLanguage: state => state.common.appState.userLang,
      publicGoal: state => state.achievements.publicGoal,
      emissionByCarfossilPerMetter: state => state.trips.emissionByCarfossilPerMetter,
    }),
    savedCo2() {
      if (this.avgAccumulators) {
        return this.formatNumber(
          Math.round(this.calculateSavedCo2()),
        );
      }
      return '-';
      // return this.formatNumber(Math.round(1691111));
    },
    // numberOfUser() {
      // if (this.avgAccumulators) {
      //   return this.challengeActiveUsers;
      // }
      // return '-';
      // return this.formatNumber(8554);
    // },
    numberOfTrips() {
      if (this.avgAccumulators) {
        return this.totalTrips;
      }
      return '-';
    },
  },
  watch: {
    publicGoal() {
      this.processData();
    },
  },
  async created() {
    await this.$store.dispatch('achievements/getPublicGoalData');
    this.processData();
    this.$emit('loaded');
    this.$log.info('PublicGoalProgressCard loaded');
  },
  methods: {
    getLanguageWithCountryCode() {
      if (this.getLanguage === 'en') {
        return 'en-US';
      }
      if (this.getLanguage === 'de') {
        return 'de-CH';
      }
      if (this.getLanguage === 'fr') {
        return 'fr-CH';
      }
      if (this.getLanguage === 'it') {
        return 'it-CH';
      }
      return 'en-US';
    },
    async processData() {
      this.avgAccumulators = this.publicGoal.data.avgAccumulators;
      this.goalEmission = this.publicGoal.data?.goalEmission ?? 0;
      this.challengeActiveUsers = this.publicGoal.data?.challengeActiveUsers ?? 0;
      this.totalTrips = this.publicGoal.data?.totalTrips ?? 0;
      this.isLoading = false;
    },
    calculateSavedCo2() {
      const values = Object.values(this.avgAccumulators);
      return values.reduce((acc, curr) => {
        // const activeUsers = curr?.activeUsers ?? values[index - 1]?.activeUsers;
        // const totalCo2accumulated = curr?.accumulatorAllUsers?.co2 ?? 0;
        const accUser = curr?.accumulatorAllUsers;
        const totalCo2 = accUser?.co2 || 0;
        const totalDistance = Object.keys(accUser).filter(key => key !== 'co2').reduce((ac, cur) => accUser[cur] + ac, 0);
        // this.$log.info('XXX', Object.keys(accUser).filter(key => key !== 'co2'));
        // this.$log.info('XXX distance', totalDistance);
        // this.$log.info('XXX', totalDistance * this.emissionByCarfossilPerMetter, totalCo2);
        const totalCo2accumulated = (totalDistance * this.emissionByCarfossilPerMetter) - totalCo2;
        if (totalCo2accumulated <= 0) {
          return acc;
        }
        return totalCo2accumulated + acc;
      }, 0);
    },
    getActiveUsers() {
      const values = Object.values(this.avgAccumulators);
      return values[values.length - 1]?.activeUsers ?? 0;
    },
    formatNumber(number) {
      return new Intl.NumberFormat(this.getLanguageWithCountryCode()).format(number);
    },
  },
};
</script>
