import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/vue';
import { cloneDeep } from 'lodash';

import service from '@/services/index';
import { displayType, getDefaultEmissionType } from '@/mixins/transportTypes';
import {
  daysInCurrentMonth,
  addToChartData,
  calcTemperatureIncrease,
  seperateTransportTypeAndFuelType,
  generateFromToPeriods,
} from '@/mixins/utils';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
// State object


const initialState = () => ({
  timeline: null,
  temperature: 0,
  cantonTemperature: 0,
  coefficients: null,
  cantonCoefficients: null,
  badTransport: ['car-fossil', 'car-hybrid', 'airplane-fossil'],
  chartData: {
    summary: {
      totalCo2: 0,
      totalDistance: 0,
      totalCo2IfElectric: 0,
      totalKwhIfElectric: 0,
    },
    fossil: [],
    electric: [],
    humanPowered: [],
    emission: [],
    noData: [],
    rawTrips: [],
    transportModeSummaries: [],
  },
  previousChartData: {
    summary: {
      totalCo2: 0,
      totalDistance: 0,
      totalCo2IfElectric: 0,
      totalKwhIfElectric: 0,
    },
    fossil: [],
    electric: [],
    humanPowered: [],
    emission: [],
    noData: [],
    rawTrips: [],
    transportModeSummaries: [],
  },
  tripCorrections: {},
  // Emission coefficients
  emissionByCarfossilPerMetter: 0.2330200043,
  emissionByCarElectricPerMetter: 0.05993999862,
  emissionByCarHybridPerMetter: 0.11823999786,
  emissionByBusFossilPerMetter: 0.09048000335,
  emissionByBusElectricPerMetter: 0.02961000061,
  emissionByCoachPerMetter: 0.04654000091,
  emissionByBikeElectricPerMetter: 0.01132999992,
  emissionByTrainPerMetter: 0.0070300002,
  emissionByTramPerMetter: 0.04279000091,
});

const state = initialState();


// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

// Actions
const actions = {
  async calculateCantonTemperature({ commit, rootState }) {
    commit('setState', ['leaderboardType', 'averageCo2']);
    const { appState } = rootState.common;
    const { user } = rootState;
    const { leaderboardState } = rootState.social;
    try {
      let temperature = 0;
      let seconds = 0;
      let cantonEmission = 0;
      if (user !== null && leaderboardState.cities) {
        if (user.city !== null) {
          const leaderboardList = leaderboardState.cities[appState.leaderboardType];
          for (let i = 0; i < leaderboardList.length; i += 1) {
            if (leaderboardList[i].id === user.city) {
              cantonEmission = leaderboardList[i].score;
            }
          }
        }
      }
      const secondsInDay = 86400;
      const daysInMonth = daysInCurrentMonth(appState.queryDate);
      switch (appState.filter) {
        case 'today':
          seconds = secondsInDay;
          break;
        case 'week':
          seconds = secondsInDay * 7;
          // It's daily avarage CO2 so it has to be multiplied by days in a week
          cantonEmission *= 7;
          break;
        case 'month':
          seconds = secondsInDay * daysInMonth;
          break;
        case 'year':
          seconds = secondsInDay * 365;
          break;
        default:
          seconds = secondsInDay;
      }
      temperature = calcTemperatureIncrease(seconds, cantonEmission);
      Vue.$log.info('Store > Calculate canton temperature', temperature);
      commit('setState', ['cantonTemperature', temperature]);
    } catch (err) {
      Sentry.captureMessage(`calculateCantonTemperature error: ${err}`);
    }
  },
  // TODO: use the new utils method temperatureIncrease here.
  async calculateTemperature({ commit, state, rootState }) {
    const { appState } = rootState.common;

    try {
      let temperature = 0;
      let seconds;
      let emission = 0;

      const secondsInDay = 86400;
      const daysInMonth = daysInCurrentMonth(appState.queryDate);
      switch (appState.filter) {
        case 'today':
          seconds = secondsInDay;
          break;
        case 'week':
          seconds = secondsInDay * 7;
          break;
        case 'month':
          seconds = secondsInDay * daysInMonth;
          break;
        case 'year':
          seconds = secondsInDay * 365;
          break;
        default:
          seconds = secondsInDay;
      }
      if (state.chartData !== null) {
        emission = state.chartData.summary.totalCo2;
      }
      temperature = calcTemperatureIncrease(seconds, emission);
      commit('setState', ['temperature', temperature]);
    } catch (err) {
      Sentry.captureMessage(`calculateTemperature error: ${err}`);
    }
  },
  async getTimeline(
    {
      commit, //
      state,
      dispatch,
      rootState,
    },
    {
      forceUpdate = false, //
      filter = null,
      queryDate = null,
      useSideEffects = true,
    } = {},
  ) {
    Vue.$log.info('Starting trips/getTimeline', `useSideEffects: ${!!useSideEffects}`);
    // await dispatch('common/setTimeout', 2000, { root: true });

    const { appState } = rootState.common;
    const { accessToken } = rootState.user;
    const { CancelToken } = axios;

    const source = CancelToken.source();
    const date = moment(queryDate || appState.queryDate);

    let from = null;
    let to = null;
    let previousFrom = null;
    let previousTo = null;

    let timelineObject;

    switch (filter || appState.filter) {
      case 'today':
        from = date.startOf('day');
        to = date.clone().endOf('day');
        previousFrom = date.clone().subtract(1, 'days').startOf('day');
        previousTo = date.clone().subtract(1, 'days').endOf('day');
        break;
      case 'week':
        from = date.startOf('isoWeek');
        to = date.clone().endOf('isoWeek');
        previousFrom = date.clone().subtract(1, 'weeks').startOf('isoWeek');
        previousTo = date.clone().subtract(1, 'weeks').endOf('isoWeek');
        break;
      case 'month':
        from = date.startOf('month');
        to = date.clone().endOf('month');
        previousFrom = date.clone().subtract(1, 'months').startOf('month');
        previousTo = date.clone().subtract(1, 'months').endOf('month');
        break;
      case 'year':
        from = date.startOf('year');
        to = date.clone().endOf('year');
        previousFrom = null;
        previousTo = null;
        break;
      default:
        break;
    }

    if (appState.cancelToken) {
      appState.cancelToken.cancel();
    }

    if (useSideEffects) {
      commit('common/setAppState', ['tripsProcessing', true], { root: true });
      await dispatch('initializeChartData');
    }

    const currentPeriod = `${from.toISOString()}-${to.toISOString()}`;
    const previousPeriod = previousFrom
      ? `${previousFrom.toISOString()}-${previousTo.toISOString()}`
      : null;

    // Cache period 15 minutes
    const cacheDuration = 900000;

    const periodTimestamp = state.timeline?.[previousPeriod || currentPeriod]?.timestamp;

    // Make sure periods do exist before using cache
    const periodsExists = state.timeline?.[currentPeriod]?.timestamp
      && state.timeline?.[previousPeriod]?.timestamp;


    Vue.$log.info('getTimeline: periodsExists:', periodsExists);

    // Use !useSideEffects for caching - or a flag more explicit
    if (Date.now() - periodTimestamp <= cacheDuration
      && !forceUpdate
      && periodsExists) {
      Vue.$log.info('getTimeline 1: Using cached trips');

      timelineObject = {
        ...state.timeline,
        currentPeriod,
        previousPeriod,
      };

      if (useSideEffects) {
        Vue.$log.info('getTimeline: Committing side effects');
        // commit('setState', ['timeline', timelineObject]);
        await dispatch('serializeChartData', { timelineObject });
        await dispatch('calculateTemperature');
      }
    } else {
      Vue.$log.info('getTimeline 2: Fetching new trips');

      if (useSideEffects) {
        commit('common/setAppState', ['cancelToken', source], { root: true });
      }

      /**
       * For optimization purposes remove some properties that are not necessary as native clients
       * tend to crash on high memory usage/storage by the web app
       * when using the endpoint `/ findByStartTimeBetween`
       */
      const stripTripProps = trips => trips.map((trip) => {
        const strippedTrip = trip;

        delete strippedTrip?.track;
        delete strippedTrip?.beginCity;
        delete strippedTrip?.endCity;
        delete strippedTrip?.meta?.weather;

        return strippedTrip;
      });

      await service.trips
        .findTrips(
          from.toISOString(), //
          to.toISOString(),
          accessToken,
          source,
        )
        /**
         * Filter out transit mode `checkIn` as the trip object lacks some properties that internal
         * code references, like meta.co2, meta.co2IfEletric etc.
         * Patching each reference instead is a bit more hairy task than it seems
         */
        .then(response => ({
          trips: response.trips.filter(trip => trip.transitMode !== 'checkIn'),
        }))
        .then((response) => {
          timelineObject = {
            ...state.timeline,
            currentPeriod,
            [currentPeriod]: {
              trips: stripTripProps(response.trips),
              timestamp: Date.now(),
            },
          };
        })
        .then(async () => {
          let previousPeriodResponse;

          if (previousFrom) {
            previousPeriodResponse = await service.trips
              .findTrips(
                previousFrom.toISOString(), //
                previousTo.toISOString(),
                accessToken,
                source,
              )
              .then(response => ({
                trips: response.trips.filter(trip => trip.transitMode !== 'checkIn'),
              }));

            timelineObject = {
              ...timelineObject,
              previousPeriod,
              [previousPeriod]: {
                trips: stripTripProps(previousPeriodResponse.trips),
                timestamp: Date.now(),
              },
            };
          } else {
            timelineObject.previousPeriod = null;
          }

          if (useSideEffects) {
            // Vue.$log.info('setState timeline 2', timelineObject);

            commit('setState', ['timeline', timelineObject]);
            commit('common/setAppState', ['cancelToken', null], { root: true });
            await dispatch('serializeChartData', { timelineObject });
            await dispatch('calculateTemperature');
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            Vue.$log.info('getTimeline cancelled by user');
          } else {
            // Sentry.captureMessage(`getTimeline error: ${ err }`);
          }
        });
    }

    if (!useSideEffects) {
      Vue.$log.info('getTimeline will return after serializeChartData');
      return dispatch('serializeChartData', {
        timelineObject,
        filter,
        queryDate,
        useSideEffects,
      });
    }


    return timelineObject;
  },
  async initializeChartData(
    { commit, rootState },
    {
      filter = null, //
      queryDate = null,
      useSideEffects = true,
    } = {},
  ) {
    const { appState } = rootState.common;

    return new Promise((resolve) => {
      const chartData = {
        emission: [],
        fossil: [],
        electric: [],
        humanPowered: [],
        noData: [],
        labels: [],
        rawTrips: [],
        transportModeSummaries: [],
        summary: {
          totalCo2: 0,
          totalDistance: 0,
          totalCo2IfElectric: 0,
          totalKwhIfElectric: 0,
        },
      };

      let amount;

      switch (filter || appState.filter) {
        case 'today':
          amount = 1;
          break;
        case 'week':
          amount = 7;
          break;
        case 'month':
          amount = daysInCurrentMonth(queryDate || appState.queryDate);
          break;
        case 'year':
          amount = 12;
          break;
        default:
          amount = 0;
      }

      for (let i = 0; i < amount; i += 1) {
        chartData.emission.push(0);
        chartData.fossil.push(0);
        chartData.electric.push(0);
        chartData.humanPowered.push(0);
        chartData.noData.push(2000);
        chartData.labels.push(i + 1);
        chartData.summary = {
          totalCo2: 0,
          totalDistance: 0,
          totalCo2IfElectric: 0,
          totalKwhIfElectric: 0,
        };
      }

      if (useSideEffects) {
        commit('setState', ['chartData', chartData]);
      }

      resolve();
    });
  },
  async serializeChartData(
    { commit, rootState },
    {
      timelineObject, //
      filter = null,
      queryDate = null,
      useSideEffects = true,
    } = {},
  ) {
    Vue.$log.info('Starting serializeChartData: queryDate:', queryDate);

    return new Promise((resolve) => {
      const { appState } = rootState.common;
      const { currentPeriod, previousPeriod } = timelineObject;

      // Clone timeline to prevent Vuex state mutations outside of mutation handlers
      const clonedTimeline = cloneDeep(timelineObject);

      const currentTimeline = clonedTimeline?.[currentPeriod]?.trips;
      const previousTimeline = clonedTimeline?.[previousPeriod]?.trips;

      const timelines = [];
      timelines.push(currentTimeline, previousTimeline);

      // Vue.$log.info('timelines', timelines);

      const chartDataModel = {
        emission: [],
        fossil: [],
        electric: [],
        humanPowered: [],
        noData: [],
        labels: [],
        rawTrips: [],
        transportModeSummaries: [],
        summary: {
          totalCo2: 0,
          totalDistance: 0,
          totalCo2IfElectric: 0,
          totalKwhIfElectric: 0,
        },
      };

      let chartData = cloneDeep(chartDataModel);
      let previousChartData = cloneDeep(chartDataModel);

      // Vue.$log.info('chartData', chartData?.summary?.totalCo2);
      // Vue.$log.info('previousChartData', previousChartData?.summary?.totalCo2);

      let amount;
      let indexFunc;
      let adjustFunc;
      let momentOf;
      let correction;

      const dateFuncs = {
        getDayOfWeek: date => date.isoWeekday(),
        getDayOfMonth: date => date.date(),
        getMonth: date => date.month(),
        setDayOfWeek: (date, index) => date.isoWeekday(index),
        setDayOfMonth: (date, index) => date.date(index),
        setMonth: (date, index) => date.month(index),
      };

      switch (filter || appState.filter) {
        case 'today':
          amount = 1;
          break;
        case 'week':
          amount = 7;
          indexFunc = dateFuncs.getDayOfWeek;
          adjustFunc = dateFuncs.setDayOfWeek;
          momentOf = 'day';
          correction = 1;
          break;
        case 'month':
          amount = daysInCurrentMonth(queryDate || appState.queryDate);
          indexFunc = dateFuncs.getDayOfMonth;
          adjustFunc = dateFuncs.setDayOfMonth;
          momentOf = 'day';
          correction = 1;
          break;
        case 'year':
          amount = 12;
          indexFunc = dateFuncs.getMonth;
          adjustFunc = dateFuncs.setMonth;
          momentOf = 'month';
          correction = 0;
          break;
        default:
          amount = 0;
      }

      for (let i = 0; i < amount; i += 1) {
        chartData.emission.push(0);
        chartData.fossil.push(0);
        chartData.electric.push(0);
        chartData.humanPowered.push(0);
        chartData.noData.push(2000);
        chartData.labels.push(i + 1);
        previousChartData.emission.push(0);
        previousChartData.fossil.push(0);
        previousChartData.electric.push(0);
        previousChartData.humanPowered.push(0);
        previousChartData.noData.push(2000);
        previousChartData.labels.push(i + 1);
      }

      timelines.forEach((timeline, timelineIndex) => {
        if (timeline) {
          if (appState.filter === 'today') {
            timeline.forEach((timelineElement, index) => {
              if (timelineElement.transitMode !== 'stay') {
                const transportObject = seperateTransportTypeAndFuelType(
                  `${timelineElement.transitMode}_${timelineElement.meta.emissionType}`,
                );

                if (transportObject.fuelType === 'default') {
                  const defaultType = getDefaultEmissionType(transportObject.transportType);
                  transportObject.fuelType = defaultType;
                }

                const displayTypeObject = seperateTransportTypeAndFuelType(
                  displayType(`${transportObject.transportType}_${transportObject.fuelType}`),
                );

                // eslint-disable-next-line no-param-reassign
                timeline[index].displayType = {
                  transitMode: displayTypeObject.transportType,
                  emissionType: displayTypeObject.fuelType,
                };

                if (timelineIndex === 0) {
                  chartData = addToChartData(chartData, 0, timeline[index]);
                  // Vue.$log.info('chartData today', chartData.summary.totalCo2);
                }
                if (timelineIndex === 1) {
                  previousChartData = addToChartData(previousChartData, 0, timeline[index]);
                  // Vue.$log.info('previousChartData today', previousChartData.summary.totalCo2);
                }
              }
            });
            // Vue.$log.info('current: serializeChartData 1 for', timeline);
          } else {
            timeline.forEach((timelineElement, i) => {
              if (timelineElement.transitMode !== 'stay') {
                // const utcOffset = timelineElement.utcOffset
                // || moment(new Date()).utcOffset() * 60000;
                const startTimestamp = moment(timelineElement.startTime);
                const endTimestamp = moment(timelineElement.endTime);
                const startIndex = indexFunc(moment(startTimestamp)) - correction;
                let endIndex = indexFunc(moment(endTimestamp)) - correction;
                const totalTravelTime = endTimestamp - startTimestamp;

                const transportObject = seperateTransportTypeAndFuelType(
                  `${timelineElement.transitMode}_${timelineElement.meta.emissionType}`,
                );

                if (transportObject.fuelType === 'default') {
                  const defaultType = getDefaultEmissionType(transportObject.transportType);
                  transportObject.fuelType = defaultType;
                }

                const displayTypeObject = seperateTransportTypeAndFuelType(
                  displayType(`${transportObject.transportType}_${transportObject.fuelType}`),
                );

                // eslint-disable-next-line no-param-reassign
                timeline[i].displayType = {
                  transitMode: displayTypeObject.transportType,
                  emissionType: displayTypeObject.fuelType,
                };

                if (startIndex > endIndex) {
                  endIndex = amount - correction;
                }

                if (startIndex === endIndex) {
                  if (timelineIndex === 0) {
                    chartData = addToChartData(chartData, startIndex, timeline[i]);
                    // Vue.$log.info('chartData NOT today', chartData.summary.totalCo2);
                  }

                  if (timelineIndex === 1) {
                    previousChartData = addToChartData(previousChartData, startIndex, timeline[i]);
                    // Vue.$log.info('previousChartData NOT today', previousChartData.summary.totalCo2);
                  }
                } else {
                  for (let index = startIndex; index <= endIndex; index += 1) {
                    let ratioTravelInTimeframe = 0;
                    const date = adjustFunc(
                      moment(queryDate || appState.queryDate),
                      index + correction,
                    );
                    if (index === startIndex) {
                      const endTimeframe = date.endOf(momentOf);
                      const timeInTimeframe = endTimeframe - startTimestamp;

                      ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;
                    } else if (index > startIndex && index < endIndex) {
                      const startTimeframe = date.startOf(momentOf);

                      const endTimeframe = date.clone().endOf(momentOf);
                      const timeInTimeframe = endTimeframe - startTimeframe;
                      ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;
                    } else if (index === endIndex) {
                      const startTimeframe = date.startOf(momentOf);

                      const timeInTimeframe = endTimestamp - startTimeframe;
                      ratioTravelInTimeframe = timeInTimeframe / totalTravelTime;
                    }
                    if (timelineIndex === 0) {
                      chartData = addToChartData(
                        chartData,
                        index,
                        timeline[i],
                        ratioTravelInTimeframe,
                      );
                    }
                    if (timelineIndex === 1) {
                      previousChartData = addToChartData(
                        previousChartData,
                        index,
                        timeline[i],
                        ratioTravelInTimeframe,
                      );
                    }
                  }
                }
              }
            });
            // Vue.$log.info('current: serializeChartData 2');
          }
        }
      });

      // Vue.$log.info('state: timeline[currentPeriod]', state.timeline[currentPeriod].trips[index].displayType);
      // Vue.$log.info('local: previousTimeline[currentPeriod]', previousTimeline[index].displayType);
      // Vue.$log.info('local: clonedTimeline', clonedTimeline);

      if (useSideEffects) {
        Vue.$log.info('serializeChartData: Committing side effects');
        commit('setState', ['timeline', clonedTimeline]);

        // TODO: Somehow this when getTimeline calls this, it's not being awaited
        // Doesn't seem to have an immediate impact but should be addressed to prevent unexpected
        // behaviour down the road
        // Vue.$log.info('serializeChartData returned');
        commit('setState', ['chartData', chartData]);
        commit('setState', ['previousChartData', previousChartData]);
        commit('common/setAppState', ['tripsProcessing', false], { root: true });
      }

      // Vue.$log.info('SerializeChartData will resolve with clonedTimeline', clonedTimeline);
      resolve(clonedTimeline);
    });
  },
  async fetchRecentTrips({ getters, dispatch }, isDocumentVisible) {
    Vue.$log.info('Recent Trips: isDocumentVisible', isDocumentVisible);

    if (isDocumentVisible) {
      const currentAndPreviousWeekTimeline = await dispatch('getTimeline', {
        filter: 'week',
        useSideEffects: false,
        queryDate: new Date().getTime(),
      });

      return getters.prepareRecentTrips(cloneDeep(currentAndPreviousWeekTimeline));
    }

    return [];
  },
};

// Getters Functions
const getters = {
  totalCo2({ chartData }) {
    if (chartData !== null) {
      return chartData.summary.totalCo2;
    }
    return 0;
  },
  prepareRecentTrips: () => (timeline) => {
    Vue.$log.info('Preparing Recent Trips');

    const { currentPeriod, previousPeriod } = generateFromToPeriods(
      new Date().getTime(),
      'week',
    );

    Vue.$log.info('currentPeriod', currentPeriod, timeline?.[currentPeriod]?.trips?.length);
    Vue.$log.info('previousPeriod', previousPeriod, timeline?.[previousPeriod]?.trips.length);

    const recentTripsPeriod = [
      timeline?.[currentPeriod]?.trips,
      timeline?.[previousPeriod]?.trips,
    ]
      ?.slice()
      ?.filter(t => t)
      ?.flat()
      ?.sort((a, b) => moment(a?.startTime) - moment(b?.startTime))
      ?.filter(t => t?.transitMode !== 'stay')
      ?.reverse()
      ?.slice(0, 3);

    Vue.$log.info('recentTripsPeriod', recentTripsPeriod);

    return recentTripsPeriod || [];
  },
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  actions,
  mutations,
};
