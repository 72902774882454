<template>
  <cc-view
    :id="id"
    class="pb-6"
    :has-full-width="true"
    :has-overflow-scroll="false"
    >
    <cc-charts-loading
      v-if="appState.isLoading"
      class="bg-blue-darkest position-absolute top-left-0 h-100"
      />

    <template v-else>
      <cc-text
        class="p-4"
        variant="body-sm"
        color="muted"
        >
        {{ leaderboardType === 'averageCo2'
          ? $t('leaderboardsDescriptionSingular')
          : null }}
        {{ leaderboardType === 'publicChallenge'
          ? $t('leaderboardsDescriptionPublicChallenge')
          : null }}
      </cc-text>

      <!-- Regular Canton leaderboard -->
      <div v-if="companies?.length">
        <cc-leaderboard-list-item
          v-for="(company, index) in companies"
          :key="index"
          :has-row-title="index === 0"
          type="companies"
          :rank="company.curPosition"
          :rank-class="getRankClass(company.curPosition)"
          :prev-rank="company.prevPosition"
          :icon="getCupIcon(company.curPosition)"
          :name="company.name"
          :score="getEmissionText(company.score)"
          :is-clickable="false"
          />
      </div>

      <!-- Did not travel leaderboard -->
      <div
        v-if="
          hasDidNotTravelList"
        class="pt-6"
        >
        <h4 class="font-weight-bold text-white text-left px-4">
          {{ didNotTravelText }}
        </h4>

        <cc-text
          class="px-4 pb-4 pt-2"
          variant="body-sm"
          color="muted"
          >
          {{ $t('didNotTravelDescription') }}
        </cc-text>

        <cc-leaderboard-list-item
          v-for="(company, index) in sortedDidNotTravelList"
          :key="`did-not-travel-${index}`"
          :did-not-travel="true"
          type="company"
          :rank="company.curPosition"
          :name="company.name"
          score=" "
          :is-clickable="false"
          />
      </div>

      <!-- Empty state for public challenge-->
      <cc-empty-state-public-challenge
        v-if="!hasDidNotTravelList && notExistedCompany"
        />
    </template>
  </cc-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';

import ccView from '@/components/constructs/View.vue';
import ccText from '@/components/primitives/Text.vue';
import ccChartsLoading from '@/components/ChartsLoading.vue';
import ccLeaderboardListItem from '@/components/social/LeaderboardListItem.vue';
import ccEmptyStatePublicChallenge from '@/components/social/EmptyStatePublicChallenge.vue';

export default {
  components: {
    ccView,
    ccText,
    ccChartsLoading,
    ccLeaderboardListItem,
    ccEmptyStatePublicChallenge,
  },
  mixins: [viewScrollMemorizer],
  props: {
    createGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: 'company-leaderboard',
      type: 'leaderboard',
      companies: null,
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      user: state => state.user.user,
      userState: state => state.user,
      leaderboardState: state => state.social.leaderboardState,
    }),
    ...mapGetters({
      hasCompanyLeaderboard: 'social/hasCompanyLeaderboard',
      getCompanies: 'social/getCompaniesLeaderboard',
      getPublicChallenge: 'social/getCompanyPublicChallengeLeaderboard',
      getRankClass: 'social/getRankClass',
      getCupIcon: 'social/getCupIcon',
      getEmissionText: 'common/getEmissionText',
    }),
    sortedDidNotTravelList() {
      if (this.leaderboardType === 'publicChallenge') {
      return this.leaderboardState?.publicChallengeCompany?.currentData?.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name)) || [];
      }
      return this.leaderboardState.company.currentData.didNotTravel
        .slice(0)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    didNotTravelText() {
      const now = this.$moment();
      const weeksAgo = now.diff(this.appState.queryDate, 'weeks');

      if (weeksAgo === 0) {
        return this.$t('didNotTravelCompanyYet');
      }

      return this.$t('didNotTravelCompany');
    },
    hasDidNotTravelList() {
      if (this.leaderboardType === 'publicChallenge') {
        return !!this.leaderboardState
        ?.publicChallengeCompany?.currentData?.didNotTravel?.length;
      }
      return this.leaderboardState?.company?.currentData?.didNotTravel?.length > 0;
    },
    queryDate() {
      return this.appState.queryDate;
    },
    leaderboardType() {
      return this.appState.leaderboardType;
    },
    leaderboard() {
      return this.leaderboardState?.company?.[this.appState?.leaderboardType];
    },
    notExistedCompany() {
      if (!this.companies) {
        return false;
      }
      return !this.companies.length;
    },
  },
  watch: {
    async queryDate() {
      await this.$store.dispatch('social/getCompanyLeaderboard');
      this.companies = this.getCompanies();
    },
    leaderboardType() {
      this.reloadLeaderboard();
    },
    leaderboard() {
      this.reloadLeaderboard();
    },
  },
  async created() {
    this.$log.info(this.appState.isLoading);
    this.setAppState(['leaderboardType', 'averageCo2']);

    const refreshTime = 900000;
    const weeksAgo = this.$moment().diff(this.appState.queryDate, 'weeks');

    if (
      !this.leaderboardState.company
      || weeksAgo !== this.leaderboardState.company.windowsAgo
      || Date.now() - refreshTime > this.leaderboardState.company.timestamp
    ) {
      await this.$store.dispatch('social/getCompanyLeaderboard');
    }
    if (
      !this.leaderboardState.publicChallengeCompany
      || Date.now() - refreshTime > this.leaderboardState.publicChallengeCompany.timestamp
    ) {
      await this.$store.dispatch('social/getPublicChallengeCompanyLeaderboard');
    }
    this.companies = this.getCompanies();
  },
  destroyed() {
    this.setAppState(['leaderboardType', 'averageCo2']);
  },
  methods: {
    ...mapMutations({
      setAppState: 'common/setAppState',
    }),
    reloadLeaderboard() {
      if (this.leaderboardType === 'averageCo2') {
        this.companies = this.getCompanies();
      } else {
        this.companies = this.getPublicChallenge();
      }
    },
  },
};
</script>
